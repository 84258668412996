import { useState, useEffect } from 'react';
import { Field, Label } from '../catalyst/fieldset';
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import isBefore from 'date-fns/isBefore';

const { afterToday } = DateRangePicker;

// Define dateRangesOptions
const dateRangesOptions = [
  {
    label: 'Last 3 days',
    value: [subDays(new Date(), 2), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 3 months',
    value: [subMonths(new Date(), 3), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 6 months',
    value: [subMonths(new Date(), 6), new Date()],
    placement: 'left',
  },
  {
    label: 'Last 12 months',
    value: [subMonths(new Date(), 12), new Date()],
    placement: 'left',
  },
];

// Fallback default date range
const defaultDateRange = [new Date(2024, 6, 1), new Date()];
export { defaultDateRange };

const DateRangeSelector = ({ dateRange, setDateRange, allAds }) => {
  const [dateRangeLabel, setDateRangeLabel] = useState('Custom Range');
  const [minDate, setMinDate] = useState(new Date(2024, 6, 1)); // Default to July 1, 2024

  // Update the minDate based on the earliest ad startDate in allAds and update dateRange to use the earliest date.
  useEffect(() => {
    if (allAds && allAds.length) {
      const earliestDate = allAds.reduce((min, ad) => {
        const adStartDate = new Date(ad.startDate);
        return adStartDate < min ? adStartDate : min;
      }, new Date());

      setMinDate(earliestDate); // Set the earliest date as minDate

      // Update the default date range to be from earliestDate to today
      setDateRange([earliestDate, new Date()]);
    }
  }, [allAds, setDateRange]);

  const updateDateRange = (range) => {
    if (!Array.isArray(range) || range.length !== 2 || !(range[0] instanceof Date) || !(range[1] instanceof Date)) {
      return [minDate, new Date()];
    }

    // Ensure the range respects the minimum date
    const startDate = isBefore(range[0], minDate) ? minDate : range[0];
    const endDate = range[1];

    // Find the label of the range
    const matchingRange = dateRangesOptions.find((defaultRange) =>
      defaultRange.value[0].getTime() === startDate.getTime() &&
      defaultRange.value[1].getTime() === endDate.getTime()
    );
    const label = matchingRange ? matchingRange.label : 'Custom Range';
    setDateRangeLabel(label);

    setDateRange([startDate, endDate]);
  };

  return (
    <Field className="flex flex-col">
      <Label><strong>Date Range</strong></Label>
      {minDate && (
        <DateRangePicker
          format="MM/dd/yyyy"
          character=" - "
          ranges={dateRangesOptions}
          label={dateRangeLabel}
          value={dateRange}
          shouldDisableDate={(date) => afterToday()(date) || isBefore(date, minDate)}
          onChange={updateDateRange}
        />
      )}
    </Field>
  );
};

export default DateRangeSelector;
