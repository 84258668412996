import React, { useEffect, useState, useMemo, useRef } from "react";
import AdCard from "./AdCard";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/shadcn/Pagination";
import { fetchAdCreativesByLibraryIds } from "./util";
import { useAppContext } from "../../contexts/AppContext";

const AdCards = ({
  ads: allAds,
  dateRange,
  activeFilterSelected,
  sortFilterSelected,
  platformFilterSelected,
  assetTypeFilterSelected,
  companiesFilterSelected,
}) => {
  const { baseURL, accessToken, adCreativesCache, prevLibraryIdsRef } = useAppContext();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [userTriggeredPageChange, setUserTriggeredPageChange] = useState(false); // New flag

  const [currentAds, setCurrentAds] = useState([]);
  const [adCreatives, setAdCreatives] = useState({});
  const [adCreativesLoading, setAdCreativesLoading] = useState(true);
  const [adCreativesError, setAdCreativesError] = useState(null);

  const adsPerPage = 40;

  // Create a ref for the ad cards section
  const adCardsRef = useRef(null);

  // Memoize filtered ads
  const filteredAds = useMemo(() => {
    return allAds
      ?.filter(ad => {
        // Date Range Filter
        const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
        const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
        const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);
        return adStartDate >= startRange && adStartDate <= endRange;
      })
      .filter(ad => {
        // Active Filter
        if (activeFilterSelected.includes('all')) return true;
        if (activeFilterSelected.includes('active')) return ad.status === 'Active';
        if (activeFilterSelected.includes('inactive')) return ad.status === 'inactive';
        return false;
      })
      .filter(ad => {
        // Platform Filter
        if (platformFilterSelected.includes('all')) return true;
        return platformFilterSelected.some(platform => {
          switch (platform) {
            case 'facebook': return ad.platforms.includes('Facebook');
            case 'instagram': return ad.platforms.includes('Instagram');
            case 'messenger': return ad.platforms.includes('Messenger');
            case 'audience_network': return ad.platforms.includes('Audience Network');
            default: return false;
          }
        });
      })
      .filter(ad => {
        // Asset Type Filter
        if (assetTypeFilterSelected.includes('all')) return true;
        return assetTypeFilterSelected.includes(ad.media_type);
      })
      .filter(ad => {
        // Company Filter using company names
        if (companiesFilterSelected.includes('all')) return true;
        return companiesFilterSelected.includes(ad.advertiser?.name);
      })
      .sort((a, b) => {
        if (sortFilterSelected === 'recent') return new Date(b.startDate) - new Date(a.startDate);
        if (sortFilterSelected === 'least') return new Date(a.startDate) - new Date(b.startDate);
        return 0;
      });
  }, [
    allAds,
    dateRange,
    activeFilterSelected,
    sortFilterSelected,
    platformFilterSelected,
    assetTypeFilterSelected,
    companiesFilterSelected,
  ]);

  // Effect for setting the current ads based on the page
  useEffect(() => {
    const startIndex = (currentPage - 1) * adsPerPage;
    const endIndex = currentPage * adsPerPage;
    const slicedAds = filteredAds.slice(startIndex, endIndex);
    
    setCurrentAds(slicedAds);
    
  }, [currentPage, filteredAds]);

  // Effect for fetching ad creatives when currentAds changes
  useEffect(() => {
    const fetchCreatives = async () => {
      setAdCreativesLoading(true);
      setAdCreativesError(null);

      try {
        const { adCreatives } = await fetchAdCreativesByLibraryIds(currentAds, baseURL, accessToken, adCreativesCache, prevLibraryIdsRef);
        setAdCreatives(adCreatives);
      } catch (error) {
        setAdCreativesError(error.message);
      } finally {
        setAdCreativesLoading(false);
      }
    };

    if (currentAds.length > 0) {
      fetchCreatives();
    }
  }, [baseURL, accessToken, currentAds]);

  // Effect to scroll to top of ad cards only when user clicks on new page
  useEffect(() => {
    if (userTriggeredPageChange && adCardsRef.current) {
      const yOffset = -275; // Adjust for fixed header height
      const yPosition =
        adCardsRef.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: yPosition, behavior: "smooth" });
      setUserTriggeredPageChange(false); // Reset the flag after scrolling
    }
  }, [currentPage, userTriggeredPageChange]);

  // **New Effect to Reset currentPage when filters change**
  useEffect(() => {
    setCurrentPage(1);
  }, [dateRange, activeFilterSelected, sortFilterSelected, platformFilterSelected, assetTypeFilterSelected, companiesFilterSelected]);

  const totalPages = Math.ceil(filteredAds.length / adsPerPage);

  const handlePageChange = (page) => {
    setUserTriggeredPageChange(true); // Set the flag when user clicks
    setCurrentPage(page);
  };

  if (currentAds.length === 0) {
    return <p className="text-center mt-4">No ads found</p>;
  }

  return (
    <>
      {/* Content */}
      <div
        ref={adCardsRef}
        className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4"
      >
        {currentAds?.map((ad) => (
          <AdCard
            key={ad.libraryId}
            ad={ad}
            adCreatives={adCreatives?.[ad.libraryId] ?? []}
          />
        ))}
      </div>

      {/* Pagination Component */}
      <div className="flex justify-center mt-4 mb-4">
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(currentPage > 1 ? currentPage - 1 : 1);
                }}
              />
            </PaginationItem>
            {Array.from({ length: totalPages }, (_, i) => (
              <PaginationItem key={i}>
                <PaginationLink
                  href="#"
                  isActive={currentPage === i + 1}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageChange(i + 1);
                  }}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            {totalPages > 5 && <PaginationEllipsis />}
            <PaginationItem>
              <PaginationNext
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlePageChange(
                    currentPage < totalPages ? currentPage + 1 : totalPages
                  );
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
};

export default AdCards;
