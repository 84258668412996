import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { Company } from '../cls/Company';
import getClients from '../api/getClients';
import getCompetitors from '../api/getCompetitors';

// Create a context
const AppContext = createContext();

// Functions to save and load state from localStorage
const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const loadFromLocalStorage = (key, defaultValue) => {
  try {
    const storedValue = localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    console.error(`Error loading ${key} from localStorage`, error);
    return defaultValue;
  }
};

// Create a provider component
const AppProvider = ({ children }) => {
  const baseURL = process.env.REACT_APP_BACKEND_BASE_URL || "http://0.0.0.0:8080"; // Fallback if environment variable is not set
  console.log('baseURL:', baseURL);
  
  // State management
  const [accessToken, setAccessToken] = useState();
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(Company.fromJson(loadFromLocalStorage('client', null))); // company object
  const [allCompetitors, setAllCompetitors] = useState([]); // array of all company objects
  const [selectedCompetitors, setSelectedCompetitors] = useState(
    loadFromLocalStorage('selectedCompetitors', []).map(comp => Company.fromJson(comp))
  );
  const [currentOffer, setCurrentOffer] = useState(loadFromLocalStorage('currentOffer', null));
  // Use ref for adsCache
  const currentAdsCache = useRef({});
  const allAdsCache = useRef({});
  const adCreativesCache = useRef({});
  const prevLibraryIdsRef = useRef([]);

  // Fetch clients once when the component mounts
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const fetchedClients = await getClients(baseURL, accessToken);
        setClients(fetchedClients);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    if (accessToken) {
      fetchClients();
    }
  }, [baseURL, accessToken]); // Dependency on baseURL ensures the effect runs only once when the component mounts

  useEffect(() => {
    if (!client) {
      return;
    }
    const fetchCompetitors = async () => {
      try {
        const fetchedCompetitors = await getCompetitors(baseURL, accessToken, client.id);
        console.log('fetchedCompetitors:', fetchedCompetitors);
        setAllCompetitors([...fetchedCompetitors, client]);
      } catch (error) {
        console.error('Error fetching competitors:', error);
      }
    }
    if (accessToken) {
      setAllCompetitors([]);
      setSelectedCompetitors([]);
      fetchCompetitors();
    }
  }, [client, accessToken]);

  // Save state to localStorage whenever it changes
  useEffect(() => {
    saveToLocalStorage('client', client);
  }, [client]);

  useEffect(() => {
    saveToLocalStorage('selectedCompetitors', selectedCompetitors);
  }, [selectedCompetitors]);

  useEffect(() => {
    saveToLocalStorage('currentOffer', currentOffer);
  }, [currentOffer]);

  return (
    <AppContext.Provider value={{
      client, clients, setClient,
      allCompetitors, 
      selectedCompetitors, setSelectedCompetitors,
      currentOffer, setCurrentOffer,
      accessToken, setAccessToken,
      baseURL,
      currentAdsCache,
      allAdsCache,
      adCreativesCache,
      prevLibraryIdsRef
    }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the AppContext
const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext };
