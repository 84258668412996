import React, { useEffect, useState } from "react";
import { Subheading } from "../../components/catalyst/heading";
import { startOfMonth, startOfWeek, endOfMonth, endOfWeek } from 'date-fns';
import { useAppContext } from "../../contexts/AppContext";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/shadcn/Card";

const ComparativeInsights = ({ selectedCompetitors, currentAdsDict, allAdsDict, dateRange }) => {
  const { client } = useAppContext();
  const [topCompaniesByActiveAds, setTopCompaniesByActiveAds] = useState([]);
  const [topCompaniesByAdsStartedOverPeriod, setTopCompaniesByAdsStartedOverPeriod] = useState({});
  const [topCompaniesByImageAds, setTopCompaniesByImageAds] = useState({});
  const [topCompaniesByVideoAds, setTopCompaniesByVideoAds] = useState({});
  const [topCompaniesByCarouselAds, setTopCompaniesByCarouselAds] = useState({});

  // Calculate metrics for top companies by active ads, ads started this week, ads started this month, image ads, and video ads
  useEffect(() => {
    if (selectedCompetitors.length > 0 && currentAdsDict && allAdsDict && dateRange) { 
      const topCompaniesAllTime = calculateTopCompaniesByActiveAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByActiveAds(topCompaniesAllTime);

      const topCompaniesImages = calculateTopCompaniesByImageAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByImageAds(topCompaniesImages);

      const topCompaniesVideos = calculateTopCompaniesByVideoAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByVideoAds(topCompaniesVideos);

      const topCompaniesCarousel = calculateTopCompaniesByCarouselAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByCarouselAds(topCompaniesCarousel);

      const topCompaniesAdsStartedOverPeriod = calculateTopCompaniesByAdsStartedOverPeriod(selectedCompetitors, allAdsDict, dateRange);
      setTopCompaniesByAdsStartedOverPeriod(topCompaniesAdsStartedOverPeriod);
    }
  }, [selectedCompetitors, currentAdsDict, allAdsDict, dateRange]);

  if (!selectedCompetitors || selectedCompetitors.length === 0 || !currentAdsDict || !allAdsDict || !dateRange || dateRange.length !== 2 || !client) {
    return null;
  }

  // Function to calculate the top companies by active ads across all time
  const calculateTopCompaniesByActiveAds = (selectedCompetitors, currentAdsDict) => {
    const topActiveAds = {};
    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor?.getId(); // Assuming getId() returns the unique identifier for the competitor
      const numCurrentAds = currentAdsDict[competitorId]?.length || 0;
      topActiveAds[competitor?.getName()] = numCurrentAds;
    });
    return topActiveAds;
  };

  const calculateTopCompaniesByAdsStartedOverPeriod = (selectedCompetitors, allAdsDict, dateRange) => {
    const topAdsStartedOverRange = {};

    const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
    const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor?.getId(); // Assuming getId() returns the unique identifier for the competitor
      const competitorAds = allAdsDict[competitorId] || [];

      let lastDaysCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
        
        // Check if the ad is active and started within the last 7 days
        if (adStartDate >= startRange && adStartDate <= endRange) {
          lastDaysCount++;
        }
      });

      topAdsStartedOverRange[competitor?.getName()] = lastDaysCount;
    });

    return topAdsStartedOverRange;
  };

  const dateRangeLength = Math.ceil((dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24)) + 1;

  // Function to calculate the top companies by live image ads
  const calculateTopCompaniesByImageAds = (selectedCompetitors, currentAdsDict) => {
    const topImageAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor?.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let imageAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isImage = ad.media_type === "single_image";

        // Check if the ad is active and is an image ad
        if (isActive && isImage) {
          imageAdsCount++;
        }
      });

      topImageAds[competitor?.getName()] = imageAdsCount;
    });

    return topImageAds;
  };

  // Function to calculate the top companies by live video ads
  const calculateTopCompaniesByVideoAds = (selectedCompetitors, currentAdsDict) => {
    const topVideoAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor?.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let videoAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isVideo = ad.media_type === "video";

        // Check if the ad is active and is a video ad
        if (isActive && isVideo) {
          videoAdsCount++;
        }
      });

      topVideoAds[competitor?.getName()] = videoAdsCount;
    });

    return topVideoAds;
  };

  const sortCompaniesByAds = (companies) => {
    if (!companies || typeof companies !== 'object') {
      return []; // Return an empty array if companies is undefined or not an object
    }
    return Object.entries(companies).sort((a, b) => b[1] - a[1]);
  };

  // Function to calculate the top companies by live carousel ads
  const calculateTopCompaniesByCarouselAds = (selectedCompetitors, currentAdsDict) => {
    const topCarouselAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor?.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let carouselAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isCarousel = ad.media_type === "carousel";

        // Check if the ad is active and is a video ad
        if (isActive && isCarousel) {
          carouselAdsCount++;
        }
      });

      topCarouselAds[competitor?.getName()] = carouselAdsCount;
    });

    return topCarouselAds;
  };

  return (
    <div className="mb-6 grid grid-cols-5 gap-3">
      {[
        { title: "Active Ads", data: topCompaniesByActiveAds },
        { title: `Ads Started Over ${dateRangeLength} Day Period`, data: topCompaniesByAdsStartedOverPeriod },
        { title: "Image Ads Live", data: topCompaniesByImageAds },
        { title: "Video Ads Live", data: topCompaniesByVideoAds },
        { title: "Carousel Ads Live", data: topCompaniesByCarouselAds },
      ].map(({ title, data }, columnIndex) => (
        <Card className="text-center">
          <div className='p-2'>
            <h3
              className="text-l pt-2 pb-4 font-semibold leading-none tracking-tight"
            >
              {title}
            </h3>
            <CardDescription>
              <ol className="list-decimal list-inside w-full">
                {sortCompaniesByAds(data).map(([company, count], rowIndex) => (
                  <li
                    key={rowIndex}
                    className="p-1 rounded text-black text-xs mb-1"
                    style={{
                      backgroundColor: "#fbfbfb",
                      border: "1px solid #bbb"
                    }}
                  >
                    {company === client?.name ? (
                      <strong>{company} - {count}</strong>
                    ) : (
                      `${company} - ${count}`
                    )}
                  </li>
                ))}
              </ol>
            </CardDescription></div>
        </Card>
      ))
      }
    </div >
  )
}

export default ComparativeInsights;

