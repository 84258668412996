import axios from 'axios';

const sendFeedback = async (baseURL, accessToken, feedback) => {
    if (!feedback) {
        console.error('No feedback provided');
        return;
    }

    try {
        // Simulate sending feedback
        console.log('Sending feedback:', feedback);
        const response = await axios.post(`${baseURL}/feedback`, {
            feedback: String(feedback),
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            }
        });
        
        console.log('Feedback sent successfully:', response.data);
    } catch (err) {
        console.error('Error sending feedback:', err);
    }
};

export default sendFeedback;