import axios from 'axios';
import { Company } from '../cls/Company';

const getCompetitors = async (baseURL, accessToken, companyId) => {
  const apiUrl = `${baseURL}/get-competitors`;
  console.log(companyId);
  try {
    const response = await axios.get(apiUrl, {
      params: { company_id: companyId },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    let companies = []
    for (let client of response.data) {
      companies.push(new Company(client.id, client.company_name, client.last_updated));
    }
    return companies;

  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data); // More detailed error from the server
      console.error('Error response status:', error.response.status); 
    } else {
      console.error('Error fetching clients:', error.message);
    }
    return []; 
  }
};

export default getCompetitors;
