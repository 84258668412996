import { Textarea } from '../../components/catalyst/textarea'
import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, Label } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState } from 'react'
import sendCompanyRequest from '../../api/requestNewCompany.js'
import { useAppContext } from '../../contexts/AppContext.js'

const RequestNewCompany = () => {
  const { baseURL, accessToken } = useAppContext()
  let [isOpen, setIsOpen] = useState(false)
  let [companyName, setCompanyName] = useState('')
  let [url, setURL] = useState('')

  const onClickSend = async () => {
    await sendCompanyRequest(baseURL, accessToken, companyName, url)
    setCompanyName('')
    setURL('')
    setIsOpen(false)
    alert('Request sent!')
  }

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)}>
        Request a New Company
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Request a New Company</DialogTitle>
        <DialogDescription>
            Please provide the company name and a link to their Meta Ad Library Page.
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Company Name</Label>
            <Input name="companyName" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          </Field>
          <Field>
            <Label>Ad Library URL</Label>
            <Input name="adLibURL" value={url} onChange={(e) => setURL(e.target.value)} />
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onClickSend}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RequestNewCompany;
