import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { jwtDecode } from 'jwt-decode';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { useAppContext } from './contexts/AppContext';
import { StackedLayout } from './components/catalyst/stacked-layout';
import SiteSidebar from './components/navigation/SiteSidebar';
import SiteNavbar from './components/navigation/SiteNavbar';
import HomePage from './pages/home/HomePage';
import AdsPage from './pages/paidAds/AdsPage';
import AuthGuard from './auth/AuthGuard'


function App() {
  const { user, getAccessTokenSilently, logout } = useAuth0();
  const { accessToken, setAccessToken } = useAppContext();

  useEffect(() => {
    let isMounted = true;
    const getUserMetadata = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: 'read:current_user openid profile email',
          },
        });
        if (!isMounted) return;

        setAccessToken(token);

        // Decode the token to get fthe expiration time
        const decodedToken = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

        // Calculate remaining time until expiration
        const timeout = expirationTime - Date.now();

        // Set a timeout to automatically logout the user when the token expires
        const timeoutId = setTimeout(() => {
          logout({ returnTo: process.env.WEBPAGE_BASE_URL});
        }, timeout);

        // Clear the timeout if the component unmounts or accessToken changes
        return () => {
          clearTimeout(timeoutId);
        };
      } catch (e) {
        console.log(e);
      }
    };

    // getUserMetadata();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, user?.sub, logout, setAccessToken]);

  const AppRoutes = () => {
    return useRoutes([
      { path: '/', element: <AdsPage /> },
      { path: '/home', element: <HomePage /> },
    ]);
  };

  return (
    <div>
      <Router>
         <StackedLayout
            navbar={<SiteNavbar />}
            sidebar={<SiteSidebar />}
            children={<AppRoutes />}
          /> 
      </Router>
    </div >
  );
}

export default App;