import { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import getCurrentAds from "../../api/getCurrentAds";
import getAllAds from "../../api/getAllAds";
import getAdCreatives from '../../api/getAdCreatives';
import getAdCreativesByLibraryIds from '../../api/getAdCreativesByLibraryIds';

export const useFetchCurrentCompetitorsAds = (competitors) => {
  const { baseURL, accessToken, currentAdsCache: adsCache } = useAppContext();
  const [currentAds, setCurrentAds] = useState({});
  const [currentAdsLoading, setCurrentAdsLoading] = useState(false);
  const [currentAdsError, setCurrentAdsError] = useState(null);

  useEffect(() => {
    const fetchAds = async () => {
      setCurrentAdsLoading(true);
      setCurrentAdsError(null);

      const fetchedAds = {};
      const newCacheEntries = {};

      try {
        const promises = competitors.map(async (competitor) => {
          const competitorId = competitor?.getId();
          if (!competitorId) {
            console.error('Invalid competitorId:', competitorId);
            return;
          }

          const cacheDuration = 60 * 60 * 1000; // 1hr cache expiration time in milliseconds
          const cachedEntry = adsCache.current[competitorId];
          const isCacheValid =
            cachedEntry && (Date.now() - cachedEntry.timestamp) < cacheDuration;

          if (isCacheValid) {
            console.log('Using cached current ads data for competitor:', competitorId);
            fetchedAds[competitorId] = cachedEntry.data;
          } else {
            console.log('Fetching new current ads data for competitor:', competitorId);
            const fetchedCompetitorAds = await getCurrentAds(
              baseURL,
              accessToken,
              competitorId
            );
            if (fetchedCompetitorAds && Object.keys(fetchedCompetitorAds).length > 0) {
              const newCacheEntry = {
                data: fetchedCompetitorAds,
                timestamp: Date.now(),
              };
              newCacheEntries[competitorId] = newCacheEntry;
              fetchedAds[competitorId] = fetchedCompetitorAds; // Update immediately
            }
          }
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Update the cache once with all new entries
        Object.keys(newCacheEntries).forEach(key => {
          adsCache.current[key] = newCacheEntries[key];
        });

        setCurrentAds(fetchedAds);
        console.log('Updated fetchedAds:', fetchedAds);
      } catch (err) {
        setCurrentAdsError(err.message);
      } finally {
        setCurrentAdsLoading(false);
      }
    };

    if (!accessToken) return;

    if (competitors && competitors.length > 0 && accessToken) {
      fetchAds();
    }
 
  }, [competitors, accessToken]); 

  return { currentAdsDict: currentAds, currentAdsLoading, currentAdsError };
};

export const useFetchAllCompetitorsAds = (competitors) => {
  const { baseURL, accessToken, allAdsCache: adsCache } = useAppContext();
  const [allAds, setAllAds] = useState({});
  const [allAdsLoading, setAllAdsLoading] = useState(false);
  const [allAdsError, setAllAdsError] = useState(null);

  useEffect(() => {
    const fetchAds = async () => {
      setAllAdsLoading(true);
      setAllAdsError(null);

      const fetchedAds = {};
      const newCacheEntries = {};

      try {
        const promises = competitors.map(async (competitor) => {
          const competitorId = competitor?.getId();
          if (!competitorId) {
            console.error('Invalid competitorId:', competitorId);
            return;
          }

          const cacheDuration = 60 * 60 * 1000; // 1hr cache expiration time in milliseconds
          const cachedEntry = adsCache.current[competitorId];
          console.debug('adsCache:', adsCache);
          console.debug('Cached Entry:', cachedEntry);
          const isCacheValid =
            cachedEntry && (Date.now() - cachedEntry.timestamp) < cacheDuration;

          if (isCacheValid) {
            console.log('Using cached all ads data for competitor:', competitorId);
            fetchedAds[competitorId] = cachedEntry.data;
          } else {
            console.log('Fetching new all ads data for competitor:', competitorId);
            const fetchedCompetitorAds = await getAllAds(
              baseURL,
              accessToken,
              competitorId
            );
            if (fetchedCompetitorAds && fetchedCompetitorAds.length > 0) {
              const newCacheEntry = {
                data: fetchedCompetitorAds,
                timestamp: Date.now(),
              };
              newCacheEntries[competitorId] = newCacheEntry;
              fetchedAds[competitorId] = fetchedCompetitorAds; // Update immediately
            }
          }
        });

        // Wait for all promises to resolve
        await Promise.all(promises);

        // Update the cache once with all new entries
        Object.keys(newCacheEntries).forEach(key => {
          adsCache.current[key] = newCacheEntries[key];
        });

        setAllAds(fetchedAds);
        console.log('Updated fetchedAds:', fetchedAds);
      } catch (err) {
        setAllAdsError(err.message);
      } finally {
        setAllAdsLoading(false);
      }
    };

    if (!accessToken) return;

    if (competitors && competitors.length > 0 && accessToken) {
      fetchAds();
    }
 
  }, [competitors, accessToken]); 

  return { allAdsDict: allAds, allAdsLoading, allAdsError };
};

export const fetchAdCreativesByLibraryIds = async (currentAds, baseURL, accessToken, adCreativesCache, prevLibraryIdsRef) => {
  const cacheDuration = 60 * 60 * 1000; // 1 hour cache expiration
  const fetchedAdCreatives = {};
  const uncachedLibraryIds = [];

  // Helper function to compare arrays
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]));
  };

  const libraryIds = currentAds.map((ad) => ({
    library_id: ad.libraryId,
    company_id: ad.company_id,
  }));

  try {
    // Check cache for each libraryId
    libraryIds.forEach((idObj) => {
      const cacheKey = `${idObj.library_id}`;
      const cachedEntry = adCreativesCache.current[cacheKey];
      const isCacheValid =
        cachedEntry && (Date.now() - cachedEntry.timestamp) < cacheDuration;

      if (isCacheValid) {
        // Use cached ad creatives
        fetchedAdCreatives[cacheKey] = cachedEntry.data;
      } else {
        // Collect uncached libraryId/company_id pairs for API call
        uncachedLibraryIds.push(idObj);
      }
    });

    // Only call the API if there are uncached libraryIds
    if (uncachedLibraryIds.length > 0) {
      const response = await getAdCreativesByLibraryIds(baseURL, accessToken, uncachedLibraryIds);
      if (response) {
        // Map response to correct cache format and store it
        Object.keys(response).forEach((key) => {
          const adCreative = response[key];
          const cacheKey = key;
          const newCacheEntry = {
            data: adCreative,
            timestamp: Date.now(),
          };
          adCreativesCache.current[cacheKey] = newCacheEntry;
          fetchedAdCreatives[cacheKey] = adCreative;
        });
      }
    }
    return { adCreatives: fetchedAdCreatives };
  } catch (error) {
    throw new Error(error.message);
  }
};

export const mergeAdsDict = (ads) => {
  const mergedAds = [];
  for (const competitorId in ads) {
    mergedAds.push(...ads[competitorId]);
  }
  return mergedAds;
}

export const countPlatforms = (ads) => {
  return ads.reduce((counts, ad) => {
    ad.platforms.forEach(platform => {
      const platformKey = platform.toLowerCase().replace(' ', '_');
      counts[platformKey] = (counts[platformKey] || 0) + 1;
    });
    return counts;
  }, {});
};

export const extractRealUrl = (redirectUrl) => {
  // Parse the redirect URL
  if (!redirectUrl) return null;

  const parsedUrl = new URL(redirectUrl);

  // Extract the query parameters
  const queryParams = new URLSearchParams(parsedUrl.search);

  // Find the original URL
  if (queryParams.has('u')) {
    const originalUrl = queryParams.get('u');
    try {
      const realUrl = decodeURIComponent(originalUrl);
      return realUrl;
    }
    catch (err) {
      console.error('Error decoding URL:', err);
      return null;
    }
  } else {
    return null;
  }
}

// Helper function to check equality between two arrays
const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i]?.getId() !== arr2[i]?.getId()) {
      return false;
    }
  }
  return true;
};