"use client";

import { Pie, PieChart } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
} from "../../components/shadcn/Card";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
  ChartLegend,
  ChartLegendContent,
} from "../../components/shadcn/Chart";

const PieChartAnalysis = ({ chartData, chartDescription = "" }) => {
  const validChartData = Array.isArray(chartData) && chartData.length > 0 ? chartData : [];

  // if no valid data is found, display a message
  if (!validChartData.length) {
    return (
      <Card className="flex flex-col">
        <CardContent className="flex-1 pb-0">
          <p className="text-center mt-4">No data found</p>
        </CardContent>
      </Card>
    )
  }

  const chartConfig = validChartData.reduce((config, { label, fill }) => {
    config[label] = {
      label,
      color: fill,
    };
    return config;
  }, {});

  return (
    <Card className="flex flex-col">
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={chartConfig}
          className="mx-auto aspect-square max-h-[300px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={validChartData}
              dataKey="value"
              nameKey="label"
              outerRadius="70%"
              fill={(entry) => entry.fill}
              label
            />
            {/* Adjust the legend styling to wrap on small screens */}
            <ChartLegend
              content={<ChartLegendContent />}
              className="-translate-y-2 gap-2 flex-wrap md:flex-nowrap [&>*]:justify-center"
            />
          </PieChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm mt-4">
        <CardDescription className="text-center">
          <strong>{chartDescription}</strong>
        </CardDescription>
      </CardFooter>
    </Card>
  );
};

export default PieChartAnalysis;
