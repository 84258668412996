import PieChartAnalysis from "../../components/analytics/PieChartAnalysis";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/shadcn/Card";
import { differenceInDays } from 'date-fns';
import { useAppContext } from '../../contexts/AppContext';
import { extractRealUrl } from "./util";
import { ChartContainer, ChartLegend, ChartLegendContent } from "../../components/shadcn/Chart";

// Helper functions 
function capitalizeFirstLetterOfEachWord(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

///////////////////////////
// Ad Chart components
///////////////////////////
// Define a color palette for the CTAs
const colorPalette = [
  "#4E79A7", // Blue
  "#F28E2B", // Orange
  "#E15759", // Red
  "#76B7B2", // Teal
  "#59A14F", // Green
  "#EDC948", // Yellow
  "#B07AA1", // Purple
  "#FF9DA7", // Pink
  "#9C755F", // Brown
  "#BAB0AC", // Gray
];

const generateAdFormatChartData = (allAdsDateFiltered) => {
  const adFormatCounts = allAdsDateFiltered.reduce((counts, ad) => {
    const mediaType = ad.media_type;
    if (!counts[mediaType]) {
      counts[mediaType] = 0;
    }
    counts[mediaType]++;
    return counts;
  }, {});
  // return [] if every count is 0
  if (Object.values(adFormatCounts).every(count => count === 0)) {
    return [];
  }
  const chartData = [
    { label: "Image", value: adFormatCounts["single_image"] ?? 0, fill: "#8fa6f0" },   // Darkened from #b3c1f5
    { label: "Video", value: adFormatCounts["video"] ?? 0, fill: "#e792a6" },          // Darkened from #f5b3c9
    { label: "Carousel", value: adFormatCounts["carousel"] ?? 0, fill: "#e7b592" },    // Darkened from #f5d4b3
  ];
  return chartData;
};

const generateAdPlatformChartData = (currentAds) => {
  const platformCounts = currentAds.reduce((counts, ad) => {
    ad.platforms.forEach((platform) => {
      const platformLowerCase = platform.toLowerCase();
      counts[platformLowerCase] = (counts[platformLowerCase] || 0) + 1;
    });
    return counts;
  }, {});

  // return empty array if all counts are 0
  if (Object.values(platformCounts).every(count => count === 0)) {
    return [];
  }

  const chartData = [
    { label: "Facebook", value: platformCounts["facebook"] ?? 0, fill: "#4E79A7" },   // Blue
    { label: "Instagram", value: platformCounts["instagram"] ?? 0, fill: "#F28E2B" }, // Orange
    { label: "Messenger", value: platformCounts["messenger"] ?? 0, fill: "#76B7B2" }, // Teal
    { label: "Audience Network", value: platformCounts["audience network"] ?? 0, fill: "#E15759" }, // Red
  ];
  return chartData;
};

// Color palette for CTA Pie Chart, maps cta -> color, dynamically generated 
const generateAdCTAChartData = (allAdsDateFiltered) => {
  // Count the number of each CTA
  let styledCTA;
  const ctaCounts = allAdsDateFiltered.reduce((counts, ad) => {
    const cta = ad?.adContent?.linkPreview?.cta;
    if (!cta) return counts;
    const styledCTA = capitalizeFirstLetterOfEachWord(cta);
    counts[styledCTA] = (counts[styledCTA] || 0) + 1;
    return counts;
  }, {});

  const ctaColors = {};
  let colorIndex = 0;

  // Map the CTA counts to chart data with corresponding colors
  const chartData = Object.entries(ctaCounts).map(([cta, value]) => {
    // Assign a color from the palette to each CTA
    if (!ctaColors[cta]) {
      ctaColors[cta] = colorPalette[colorIndex % colorPalette.length];
      colorIndex++;
    }
    const fill = ctaColors[cta];

    return { label: cta, value, fill };
  });

  return chartData;
};

const generateAdSitePlacementChartData = (allAdsDateFiltered) => {
  let homePageCount = 0;
  let productPageCount = 0;
  let collectionPageCount = 0;
  let customLPCount = 0;

  allAdsDateFiltered.forEach((ad) => {
    const realUrl = extractRealUrl(ad.adContent?.linkPreview?.url);
    if (realUrl) {
      const parsedRealUrl = new URL(realUrl);

      // Check for Home Page
      if (parsedRealUrl.pathname === '/' && !parsedRealUrl.search && !parsedRealUrl.hash) {
        homePageCount++;
      } else if (parsedRealUrl.pathname.includes('/product')) {
        // Check for Product Page
        productPageCount++;
      } else if (parsedRealUrl.pathname.includes('/collection') || parsedRealUrl.pathname.includes('/category') || parsedRealUrl.pathname.includes('/shop/')) {
        // Check for Collection Page
        collectionPageCount++;
      } else if (parsedRealUrl.pathname.includes('/pages/') || parsedRealUrl.pathname.includes('/lp') || parsedRealUrl.pathname.includes('/promo')) {
        // Check for Custom Landing Page
        customLPCount++;
      } else {
        // Fallback as Product Page (or you can add other logic)
        productPageCount++;
      }
    }
  });

  // return empty array if all properties are 0
  if (homePageCount === 0 && productPageCount === 0 && collectionPageCount === 0 && customLPCount === 0) {
    return [];
  }
  const chartData = [
    { label: "Home", value: homePageCount ?? 0, fill: "#e76b6b" },     // Blue
    { label: "Product", value: productPageCount ?? 0, fill: "#7075e0" }, // Orange
    { label: "Collection", value: collectionPageCount ?? 0, fill: "#6be78e" }, // Teal
    { label: "Custom", value: customLPCount ?? 0, fill: "#e7d36b" }, // Red
  ];

  return chartData;
};

////////////////////////////////////////
// Ad Card calculation functions 
////////////////////////////////////////
const calculateAverageAdPostFrequency = (allAds, periodType) => {
  if (allAds.length === 0) return { average: 0, dataPeriodDays: 0 };

  const today = new Date();
  const earliestAdDate = new Date(Math.min(...allAds.map(ad => new Date(ad.startDate))));
  let dataPeriodDays = differenceInDays(today, earliestAdDate) + 1; // +1 to include both start and end dates

  // Cap the data period at 90 days
  dataPeriodDays = Math.min(dataPeriodDays, 90);
  const dataPeriodStart = new Date(today - (dataPeriodDays - 1) * 24 * 60 * 60 * 1000);

  // Filter ads within the data period
  const adsInPeriod = allAds.filter(ad => new Date(ad.startDate) >= dataPeriodStart);
  const totalAds = adsInPeriod.length;

  let average = 0;

  if (periodType === 'week') {
    const totalWeeks = dataPeriodDays / 7;
    average = totalAds / totalWeeks;
  } else if (periodType === 'month') {
    const averageDaysInMonth = 30.44; // Average days in a month over a 4-year period
    const totalMonths = dataPeriodDays / averageDaysInMonth;
    average = totalAds / totalMonths;
  }

  return { average, dataPeriodDays };
};

// Main component
const Analytic = ({ companyId, currentAds, allAds, dateRange }) => {
  const { allCompetitors } = useAppContext();
  if (!currentAds || !allAds || dateRange.length !== 2) {
    return null;
  }
  const title = allCompetitors.find(company => company.getId() === companyId)?.getName();
  
  const numActiveAds = currentAds.length;

  const allAdsDateFiltered = allAds?.filter(ad => {
    const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
    const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
    const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);
    return adStartDate >= startRange && adStartDate <= endRange;
  });

  const numTotalAds = allAdsDateFiltered.length
  const dateRangeLength = Math.ceil((dateRange[1] - dateRange[0]) / (1000 * 60 * 60 * 24)) + 1;

  // const platformChartData = generateAdPlatformChartData(currentAds);
  const adFormatChartData = generateAdFormatChartData(allAdsDateFiltered);
  const adCTAData = generateAdCTAChartData(allAdsDateFiltered);
  const adSitePlacementChartData = generateAdSitePlacementChartData(allAdsDateFiltered);

  // Calculate averages and data periods
  const { average: averageAdPostFrequencyPerWeek, dataPeriodDays: weekDataPeriod } = calculateAverageAdPostFrequency(allAds, 'week');
  const { average: averageAdPostFrequencyPerMonth, dataPeriodDays: monthDataPeriod } = calculateAverageAdPostFrequency(allAds, 'month');

  return (
    <div className="bg-gray-50 p-4 rounded-2xl mb-4">
      <h2 className="mb-4 text-xl"><strong>{title}</strong></h2>
      <div className="flex">
        {/* Left Column: Cards */}
        <div className="flex flex-col space-y-4 flex-4">
          <Card className="text-center">
            <CardHeader>
              <CardTitle tag="h5">{numActiveAds}</CardTitle>
              <CardDescription>Active Ads</CardDescription>
            </CardHeader>
          </Card>

          <Card className="text-center">
            <CardHeader>
              <CardTitle tag="h5">{numTotalAds}</CardTitle>
              <CardDescription>Ads Started Over {dateRangeLength} Day Period</CardDescription>
            </CardHeader>
          </Card>
        </div>

        {/* Right Column: Pie Charts */}
        <div className="flex flex-col flex-grow ml-8 h-full">
          <div className="flex space-x-3">
            {/* Uncomment if you want to include the platform chart
            <div className="w-1/4">
              <PieChartAnalysis
                chartData={platformChartData}
                chartDescription="Platform for Active Ads"
              />
            </div>
            */}
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adFormatChartData}
                chartDescription="Format for Active Ads"
              />
            </div>
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adCTAData}
                chartDescription="CTA for Active Ads"
              />
            </div>
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adSitePlacementChartData}
                chartDescription="Site Placement for Active Ads"
              />
            </div>
          </div>

          {/* Average Analytics */}
          <div className="flex flex-wrap space-x-3 mt-8">
            <Card className="text-center flex-1 w-1/3">
              <CardHeader>
                <CardTitle tag="h5">{averageAdPostFrequencyPerWeek.toFixed(2)}</CardTitle>
                <CardDescription>
                  Average Ad Posts Per Week
                  <br />
                  <small><strong>(Past {weekDataPeriod} Days)</strong></small>
                </CardDescription>
              </CardHeader>
            </Card>

            <Card className="text-center flex-1 w-1/3">
              <CardHeader>
                <CardTitle tag="h5">{averageAdPostFrequencyPerMonth.toFixed(2)}</CardTitle>
                <CardDescription>
                  Average Ad Posts Per Month
                  <br />
                  <small><strong>(Past {monthDataPeriod} Days)</strong></small>
                </CardDescription>
              </CardHeader>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytic;
